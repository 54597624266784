
import { HttpClient } from './http-client';
import { B2BAddresses } from './B2BAddresses';
import { EditorialTopicsList } from './EditorialTopicsList';
import { EditorialTopicsSearch } from './EditorialTopicsSearch';
import { EditorialTopicsArticle } from './EditorialTopicsArticle';
import { Events } from './Events';
import { Jjkperson } from './Jjkperson';
import { Marketing } from './Marketing';
import { NewsArticle } from './NewsArticle';
import { Redirect } from './Redirect';
import { SapOrder } from './SapOrder';
import { Sds } from './Sds';
const publicClient = new HttpClient({
	baseUrl: process.env.NODE_ENV === 'production' ? '/wcs/resources':'/api/resources',
});
const privateClient = new HttpClient({
	baseUrl: (process.env.USE_MOCK === 'true' ? 'http://localhost:' + process.env.MOCK_HOST_PORT : process.env.TRANSACTION_ORIGIN as string) + '/wcs/resources',
});

export const customB2BAddresses = (pub: boolean) => new B2BAddresses(pub ? publicClient : privateClient);
export const customEditorialTopicsList = (pub: boolean) => new EditorialTopicsList(pub ? publicClient : privateClient);
export const customEditorialTopicsSearch = (pub: boolean) => new EditorialTopicsSearch(pub ? publicClient : privateClient);
export const customEditorialTopicsArticle = (pub: boolean) => new EditorialTopicsArticle(pub ? publicClient : privateClient);
export const customEvents = (pub: boolean) => new Events(pub ? publicClient : privateClient);
export const customJjkperson = (pub: boolean) => new Jjkperson(pub ? publicClient : privateClient);
export const customMarketing = (pub: boolean) => new Marketing(pub ? publicClient : privateClient);
export const customNewsArticle = (pub: boolean) => new NewsArticle(pub ? publicClient : privateClient);
export const customRedirect = (pub: boolean) => new Redirect(pub ? publicClient : privateClient);
export const customSapOrder = (pub: boolean) => new SapOrder(pub ? publicClient : privateClient);
export const customSds = (pub: boolean) => new Sds(pub ? publicClient : privateClient);
