/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ID } from '@/data/types/Basic';
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

export const contentManifestCustom: Record<string, ComponentType<{ id: ID }>> = {
	ContentCarousel: dynamic(() =>
		import('@/components/content/ContentCarousel').then((mod) => mod.ContentCarousel)
	),

	CatalogEntryRecommendation: dynamic(() =>
		import('@/components/content/CatalogEntryRecommendation').then(
			(mod) => mod.CatalogEntryRecommendation
		)
	),
	ShopByCategory: dynamic(() =>
		import('@/components/content/ShopByCategory').then((mod) => mod.Category)
	),
	ProductDetails: dynamic(() =>
		import('@/components/content/ProductDetails').then((mod) => mod.ProductDetails)
	),
	Cart: dynamic(() => import('@/components/content/Cart').then((mod) => mod.Cart)),
	NewCustomerService: dynamic(() =>
		import('@/components/content/NewCustomerService').then((mod) => mod.NewCustomerService)
	),
	NewCustomerServiceSidebar: dynamic(() =>
		import('@/components/content/NewCustomerServiceSidebar').then(
			(mod) => mod.NewCustomerServiceSidebar
		)
	),
	EmailPreferences: dynamic(() =>
		import('@/components/content/EmailPreferences').then((mod) => mod.EmailPreferences)
	),
	OrderStatus: dynamic(() =>
		import('@/components/content/OrderStatus').then((mod) => mod.OrderStatus)
	),
	Review: dynamic(() => import('@/components/content/Review').then((mod) => mod.Review)),
	AccountSettings: dynamic(() =>
		import('@/components/content/AccountSettings').then((mod) => mod.AccountSettings)
	),
	AddressBook: dynamic(() =>
		import('@/components/content/AddressBook').then((mod) => mod.AddressBook)
	),
	ReorderDetails: dynamic(() =>
		import('@/components/content/ReorderDetails').then((mod) => mod.ReorderDetails)
	),

	StaticBreadcrumbTrail: dynamic(() =>
		import('@/components/content/StaticBreadcrumbTrail').then((mod) => mod.StaticBreadcrumbTrail)
	),
	RequisitionLists: dynamic(() =>
		import('@/components/content/RequisitionLists').then((mod) => mod.RequisitionLists)
	),
	RequisitionListsTable: dynamic(() =>
		import('@/components/content/RequisitionListsTable').then((mod) => mod.RequisitionListsTable)
	),
	RequisitionListDetails: dynamic(() =>
		import('@/components/content/RequisitionListDetails').then((mod) => mod.RequisitionListDetails)
	),
	CheckOutHeader: dynamic(() =>
		import('@/components/content/MultiCheckOut/Header').then((mod) => mod.Header)
	),
	CheckOutFooter: dynamic(() =>
		import('@/components/content/MultiCheckOut/Footer').then((mod) => mod.Footer)
	),
	AccessVideo: dynamic(() =>
		import('@/components/content/AccessVideo').then((mod) => mod.AccessVideo)
	),
	AvFooter: dynamic(() =>
		import('@/components/content/AccessVideo/AvFooter').then((mod) => mod.AvFooter)
	),
	CheckOut: dynamic(() => import('@/components/content/CheckOut').then((mod) => mod.CheckOut)),
	// CheckOutOrderSummary: dynamic(() =>
	// 	import('@/components/content/MultiCheckOut/OrderSummary').then((mod) => mod.CheckOutOrderSummary)
	// ),
	Register: dynamic(() => import('@/components/content/Register').then((mod) => mod.Register)),
	SessionError: dynamic(() =>
		import('@/components/content/SessionError').then((mod) => mod.SessionError)
	),
	MinimizedHeader: dynamic(() =>
		import('@/components/content/MinimizedHeader').then((mod) => mod.MinimizedHeader)
	),
	CatalogEntryList: dynamic(() =>
		import('@/components/content/CatalogEntryList').then((mod) => mod.CatalogEntryList)
	),
	PlpResultsHeader: dynamic(() =>
		import('@/components/content/PlpResultsHeader').then((mod) => mod.PlpResultsHeader)
	),
	SearchResultsHeader: dynamic(() =>
		import('@/components/content/SearchResultsHeader').then((mod) => mod.SearchResultsHeader)
	),
	OrderConfirmation: dynamic(() =>
		import('@/components/content/OrderConfirmation').then((mod) => mod.OrderConfirmation)
	),
	CoBrandCategoryDetail: dynamic(() =>
		import('@/components/content/CoBrandCategoryDetail').then((mod) => mod.CoBrandCategoryDetail)
	),
	ProductInfo: dynamic(() =>
		import('@/components/content/ProductInfo').then((mod) => mod.ProductInfo)
	),
	ProductReview: dynamic(() =>
		import('@/components/content/ProductReview').then((mod) => mod.ProductReview)
	),
	ContactExperts: dynamic(() =>
		import('@/components/content/ContactExperts').then((mod) => mod.ContactExperts)
	),
	NonPdpLeadGeneration: dynamic(() =>
		import('@/components/content/NonPdpLeadGeneration').then((mod) => mod.NonPdpLeadGeneration)
	),
	AllEvents: dynamic(() => import('@/components/content/AllEvents').then((mod) => mod.AllEvents)),
	CustomEspot: dynamic(() =>
		import('@/components/content/CustomEspot').then((mod) => mod.CustomEspot)
	),

	NewsEvent: dynamic(() => import('@/components/content/NewsEvent').then((mod) => mod.NewsEvent)),
	Event: dynamic(() => import('@/components/content/Event').then((mod) => mod.Event)),
	SafetyDataSearch: dynamic(() =>
		import('@/components/content/SafetyDataSheetSearch').then((mod) => mod.SafetyDataSearch)
	),
	AdvForm: dynamic(() => import('@/components/content/AdvForm').then((mod) => mod.AdvForm)),
	Video: dynamic(() => import('@/components/content/Video').then((mod) => mod.Video)),
	VideoCarousel: dynamic(() =>
		import('@/components/content/VideoCarousel').then((mod) => mod.VideoCarousel)
	),
	CustomBreadcrumb: dynamic(() =>
		import('@/components/content/CustomBreadcrumb').then((mod) => mod.CustomBreadcrumb)
	),
	NewsArticle: dynamic(() =>
		import('@/components/content/NewsArticle').then((mod) => mod.NewsArticle)
	),
	DynamicTable: dynamic(() =>
		import('@/components/content/AdvantageResources').then((mod) => mod.AdvantageResourcesDataTable)
	),
	ContentEntryList: dynamic(() =>
		import('@/components/content/ContentEntryList').then((mod) => mod.ContentEntryList)
	),
	AdvantageSignin: dynamic(() =>
		import('@/components/content/AdvantageSignin').then((mod) => mod.AdvantageSignin)
	),
	AllNews: dynamic(() => import('@/components/content/AllNews').then((mod) => mod.AllNewsWidget)),
	ReorderComp: dynamic(() =>
		import('@/components/content/ReorderComp').then((mod) => mod.ReorderComp)
	),
	CheckOutShipping: dynamic(() =>
		import('@/components/content/MultiCheckOut/parts/Shipping').then((mod) => mod.CheckOutShipping)
	),
	CheckOutPayment: dynamic(() =>
		import('@/components/content/MultiCheckOut/parts/Payment').then((mod) => mod.CheckOutPayment)
	),
	CheckOutReview: dynamic(() =>
		import('@/components/content/MultiCheckOut/parts/Review').then((mod) => mod.CheckOutReview)
	),
	RelatedEvent: dynamic(() =>
		import('@/components/content/RelatedEvent').then((mod) => mod.RelatedEvent)
	),
	TopicIndex: dynamic(() =>
		import('@/components/content/TopicIndex').then((mod) => mod.TopicIndex)
	),
	SimpleListNews: dynamic(() =>
		import('@/components/content/SimpleListNews').then((mod) => mod.SimpleListNews)
	),
	Login: dynamic(() => import('@/components/content/Login').then((mod) => mod.Login)),
	FeedbackForm: dynamic(() =>
		import('@/components/content/FeedbackForm').then((mod) => mod.FeedbackForm)
	),
	SecuritySealsFinder: dynamic(() =>
		import('@/components/content/SecuritySealsFinder').then((mod) => mod.SecuritySealsFinder)
	),
	FlipPlacardFinder: dynamic(() =>
		import('@/components/content/FlipPlacardFinder').then((mod) => mod.FlipPlacardFinder)
	),
	MicrodataContent: dynamic(() =>
		import('@/components/content/MicrodataContent').then((mod) => mod.MicroDataContent)
	),
	TopicIndexHeadline: dynamic(() =>
		import('@/components/content/TopicIndexHeadline').then((mod) => mod.TopicIndexHeadline)
	),
	TopicIndexContentHeadline: dynamic(() =>
		import('@/components/content/TopicIndexContentHeadline').then(
			(mod) => mod.TopicIndexContentHeadline
		)
	),
	Error404: dynamic(() => import('@/components/content/Error404').then((mod) => mod.Error404)),
	LabourLawPosterCompliance: dynamic(() =>
		import('@/components/content/LabourLawPosterCompliance').then(
			(mod) => mod.LabourLawPosterCompliance
		)
	),
};
